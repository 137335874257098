<!--
 * @Descripttion:专家详情
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-05 09:00:10
-->
<template>
    <div>
        <van-nav-bar title="专家详情" left-arrow @click-left="$router.push('/home')"/>
        <div class="flex-item pad">
            <i class="status-ban" v-if="expert.enableStatus =='1'"></i>
            <i class="wh20"
                :class="{'icon-male' : expert.sex =='1', 'icon-female' : expert.sex=='2','icon-zhongxing' : !expert.sex}"
            >
            </i>
            <h3 class="f5 mar-l2">{{expert.name}}</h3>
            <i class="wh05 mar-l2"
                :class="{'icon-boy' : expert.sex =='1', 'icon-girl' : expert.sex=='2'}"
            ></i>
        </div>
        <div class="w-line"></div>
        <div class="info pad">
            <p>
                <span>专家编号</span>
                <span>{{expert.num}}</span>
            </p>
            <p>
                <span>专家姓名</span>
                <span>{{expert.name}}</span>
            </p>
            <p>
                <span>手机号</span>
                <span>{{expert.mobilePhone}}</span>
            </p>
            <p>
                <span>身份证号</span>
                <span>{{expert.idCard}}</span>
            </p>
            <p>
                <span>性别</span>
                <span v-if="expert.sex=='1'">男</span>
				<span v-else-if="expert.sex=='2'">女</span>
				<span v-else>保密</span>
            </p>
            <p>
                <span>出生日期</span>
                <span>{{ getBirthDay }}</span>
            </p>

            <p>
                <span>地址</span>
                <span>{{expert.addr}}</span>
            </p>
        </div>
        <div class="w-line"></div>
        <div class="info pad">
            <p>
                <span>工作单位</span>
                <span>{{expert.orgName}}</span>
            </p>
            <p><span>评审专业</span></p>
            <ul class="w-100 mar-t2">
				<li class="center van-ellipsis greenLi fw mar-t1" v-for="(item,index) in expert.majors" :key="index" >
                    {{item.parentName + "-" +item.majorName}}
                </li>
            </ul>
            <p v-if="expert.remark">
                <span>备注</span>
                <span>{{expert.remark}}</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
	data(){
		return {
			//专家
			expert:{
                majors:[],
            },
			//出生日期
			birthDay:'',
			//地址
			addr:'',
            queryParam: {
                orgId: "",
                expertId:''
            },
            pageParams:{
                pageNo:1,
                pageSize:20
            },
			workweixin: false,				//企业微信
			openUserid:	"",
		}
	},
	mounted:function(){
		//企微微信标志
		this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
		if(this.workweixin){
			this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
		}
		//接收专家信息
        this.$ls.remove("orgExpertMajorsInfo");
		let expertStr = this.$route.query.expertInfo
		if(expertStr!=null&&expertStr!=''){
            this.expert = JSON.parse(expertStr)
            this.queryParam.expertId = this.expert.id;
		}
        if(this.$ls.get('edCurrentOrg')){
            this.queryParam.orgId = this.$ls.get('edCurrentOrg').id;
        }   
        this.getTreeData();     
    },

    computed:{
        getBirthDay(){
            if(this.expert.birthDate){
                let tempDate = new Date(this.expert.birthDate)
                let month =  tempDate.getMonth()>8 ? tempDate.getMonth()+1 : '0' + (tempDate.getMonth()+1)
                let day = tempDate.getDate() > 9 ? tempDate.getDate() : '0' + tempDate.getDate()
                return tempDate.getFullYear() + "-" + month + "-" + day
            }else{
                return ''
            }
        }
    },
	methods:{
       //获取专业树结构
        getTreeData(){
            var params = Object.assign({}, this.queryParam)
            params.pageNo = this.pageParams.pageNo;
            params.pageSize = this.pageParams.pageSize;
            params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
            let accesstoken = this.$ls.get("token")
            this.$ajax({
                methods: 'get',
                url: '/gateway/ed/wx/wxEdReviewMajorTwoThreeClassController/voByOrgExpertList/',
                headers:{
                  'X-Access-Token':this.$ls.get("token")
                },
                params: params,
            }).then(res=>{
                if (res.data.success) {
                    let allData={};
                    allData.gongchengList = res.data.result.gongchengList==null?[]:res.data.result.gongchengList;
                    allData.huowuList = res.data.result.huowuList==null?[]:res.data.result.huowuList;
                    allData.fuwuList = res.data.result.fuwuList==null?[]:res.data.result.fuwuList;
                    this.$ls.set("orgExpertMajorsInfo",allData);
                    this.getExpertOpenMajors();
                } else {
                    this.$toast.fail("失败")
                    console.log(res.data.message)
                }
            });
        },
        //遍历专业树结构，获取选中的数据
        getExpertOpenMajors(){
            let openMajors = [];
            let allData = this.$ls.get("orgExpertMajorsInfo");
            this.expert.majors = [];
            if(this.$ls.get("orgExpertMajorsInfo")==''){
                return;
            }
            if(allData.gongchengList.length>0){
                for(let i=0;i<allData.gongchengList.length;i++){
                    let obj = allData.gongchengList[i];
                    if(obj.children.length>0){
                        for(let j=0;j<obj.children.length;j++){
                            let childObj = obj.children[j];

                            if(childObj.checked){
                                childObj.parentName= obj.majorName
                                openMajors.push(childObj);
                            }
                        }
                    }
                }
            };
            if(allData.huowuList.length>0){
                for(let i=0;i<allData.huowuList.length;i++){
                    let obj = allData.huowuList[i];
                    if(obj.children.length>0){
                        for(let j=0;j<obj.children.length;j++){
                            let childObj = obj.children[j];
                            if(childObj.checked){
                                childObj.parentName= obj.majorName
                                openMajors.push(childObj);
                            }
                        }
                    }
                }
            };
            if(allData.fuwuList.length>0){
                for(let i=0;i<allData.fuwuList.length;i++){
                    let obj = allData.fuwuList[i];
                    if(obj.children.length>0){
                        for(let j=0;j<obj.children.length;j++){
                            let childObj = obj.children[j];
                            if(childObj.checked){
                                childObj.parentName= obj.majorName
                                openMajors.push(childObj);
                            }
                        }
                    }
                }
            };
            this.expert.majors  = openMajors;
            this.$forceUpdate();//强制刷新
        },   
        //下一个方法
	}
}
</script>

<style scoped>
    .info>p:not(:first-child){margin-top: 0.3rem;}
    .info>p>span:nth-child(odd){color: #737373; width: 2rem; display: table-cell; font-weight: bold;}
    .info>p>span:nth-child(even){font-weight: bold;display: table-cell;}
    .mar-t1{margin-top: 0.2rem;}
    .wh20, .wh05{flex-shrink: 0}
    .flex-item{position: relative;}
    .status-ban{width: 1.5rem; height: 1.5rem;}
</style>
